.main-nav {
    position: fixed;
    width: 320px;
    height: 100dvh;
    overflow-y: auto;
    background-color: var(--section-bg-color);
    overflow-x: hidden;
}

.main-nav__section {
    padding: 1.5rem 1.5rem 0 1.5rem;
}

.menu__header {
    font-size: 1.25rem;
    padding: 1rem 0;
    color: #d2beef;
}

.menu__link {
    transition: background-color 0.3s ease-in, border-radius 0.3s ease-in;
    color: #fff;
    display: block;
    padding: 1rem;
    text-decoration: none;

}

.menu__link:hover, .menu__link_active {
    background-color: #621cc3;
    border-radius: 5px;
}