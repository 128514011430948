.sliding-text {
    position: relative;
    transition:
        opacity 1s ease-in-out,
        left 1s ease-in-out;
}

.fade-in {
    left: 0;
    opacity: 1;
}

.fade-out {
    left: -20px;
    opacity: 0;
}
