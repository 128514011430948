.login {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: var(--section-bg-color);
    padding: 30px;
    gap: 1rem;
    border-radius: 20px;
}

.login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
}
.login__google {
    background-color: #fff;
    border: 1px solid rgb(193, 199, 208);
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.6rem;
}
