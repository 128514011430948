.wizard-form {
    display: flex;
    align-content: stretch;
    gap: 0.75rem;
}

.wizard-form__control {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.welcome-message {
    max-width: 800px;
}

.welcome-message p {
    margin-bottom: 1rem;
}

.section_wizard {
    display: flex;
    flex-direction: column;
    align-items: center;
}
