:root {
    --section-bg-color: #222433;
    --primary-color: #621cc3;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
}

html,
body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    background-color: #14141e;
    color: #fff;
}

#root {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'nav main';
    gap: 2rem;
}

main {
    margin-right: 2rem;
    grid-column-start: 2;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

.main-nav {
    position: fixed;
    width: 320px;
    height: 100dvh;
    overflow-y: auto;
    background-color: var(--section-bg-color);
    overflow-x: hidden;
}

.main-nav__section {
    padding: 1.5rem 1.5rem 0 1.5rem;
}

.link {
    text-decoration: none;
    color: #fff;
}

.link_secondary {
    color: #b78fef;
}

.link:hover {
    color: var(--primary-color);
}

.menu__header {
    font-size: 1.25rem;
    padding: 1rem 0;
    color: #d2beef;
}

.menu__link {
    transition:
        background-color 0.3s ease-in,
        border-radius 0.3s ease-in;
    color: #fff;
    display: block;
    padding: 1rem;
}

.menu__link:hover,
.menu__link_active {
    background-color: #621cc3;
    border-radius: 5px;
    color: #fff;
}

.section {
    background-color: var(--section-bg-color);
    border-radius: 15px;
    padding: 2rem;
    margin-bottom: 2rem;
}

.section__header {
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    align-items: center;
}

.section__title {
    font-size: 1.4rem;
    font-weight: bold;
}

.directory-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.directory-list__box {
    transition: background-color 0.2s ease-in;
    padding: 1rem;
    background-color: hsl(233 20% 23% / 1);
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    color: #fff;
    text-decoration: none;
    aspect-ratio: 1;
}

.directory-list__box:hover {
    background-color: #621cc3;
}

.directory-list__box_new {
    background-color: var(--primary-color);
}

[type='search'] {
    flex: 1 0 auto;
    padding: 1rem;
    border-radius: 30px;
    border: none;
}

th {
    text-align: left;
}

table {
    border-collapse: collapse;
}

.files-table {
    width: 100%;
    margin-bottom: 2rem;
}

td,
th {
    padding: 1rem;
    border-bottom: 1px solid #404354;
}

th {
    color: #d2beef;
}

.upload-area {
    border: 1px dashed #404354;
    margin-bottom: 2rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    background-color: hsl(233 20% 23% / 1);
}

.btn {
    background-color: var(--primary-color);
    padding: 0.8rem 1rem;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border: 0;
    border-radius: 10px;
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in;
}

.btn_secondary {
    background-color: #2f3246;
}

.btn_small {
    padding: 0.6rem;
}

input,
textarea,
.control {
    background-color: #2f3246;
    color: #fff;
    border: 0;
    padding: 1rem;
    font-size: 16px;
    border-radius: 10px;
}

.react-daterange-picker__wrapper {
    background-color: #2f3246;
    color: #fff;
    border: none !important;
    padding: 0.75rem;
    font-size: 16px;
    border-radius: 10px;
}

.react-daterange-picker__button svg {
    stroke: #fff;
}

textarea {
    min-height: 150px;
    resize: vertical;
}

.question-box {
    display: flex;
}

.question-box:not(:last-child) {
    margin-bottom: 1rem;
}

.question-box__btn {
    border-radius: 0 10px 10px 0;
}

.question-box__input {
    border-radius: 10px 0 0 10px;
    flex: 1 0 auto;
}

.file-description {
    margin-bottom: 1rem;
    line-height: 2;
    font-size: 1.2rem;
}

.modal-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: var(--section-bg-color);
    padding: 2rem;
    border-radius: 15px;
    max-width: 640px;
}

.modal__header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.modal__footer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.question {
    border-bottom: 1px dashed #404354;
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
    color: #d2beef;
}

p {
    line-height: 1.5;
}

[hidden] {
    display: none !important;
}

.copy-code {
    width: 100%;
    min-height: 80px;
    white-space: pre;
    background-color: #2f3246;
    color: #fff;
    border: 1px solid #404354;
    margin: 0.5rem 0;
    resize: block;
    padding: 0.5rem;
    min-height: 140px;
}

button {
    background: none;
    border: none;
}

.dropdown__content {
    background-color: hsl(233 20% 23% / 1);
    padding: 0.25rem;
    border-radius: 10px;
    min-width: 150px;
}

.dropdown__item {
    color: #fff;
    display: flex;
    text-decoration: none;
    padding: 0.5rem 0.8rem;
    border-radius: 7px;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
}

.dropdown__item:hover {
    background-color: #621cc3;
}

.btn-icon {
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-icon:hover {
    background-color: #621cc3;
}

.btn-action {
    cursor: pointer;
    padding: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    gap: 0.5rem;
    color: #fff;
    text-decoration: none;
}

.btn-action:hover {
    background-color: #621cc3;
}

.form__control {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.cell_action {
    text-align: right;
}

.disclosure {
    padding: 0.8rem;
    background-color: #2f3246;
    width: 100%;
    text-align: left;
    color: #fff;
}

.disclosure button {
    color: #fff;
    padding: 0;
    font-size: 1rem;
}

.disclosure:first-child {
    border-radius: 10px 10px 0 0;
}

.disclosure:last-child {
    border-radius: 0 0 10px 10px;
}

.disclosure:not(:last-child) {
    border-bottom: 1px solid #404354;
}

.asnwer {
    font-size: 1.1rem;
    line-height: 1.7;
}

.asnwer ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 1rem;
}

.asnwer ol {
    list-style-type: decimal;
    list-style-position: inside;
}

.asnwer p:not(:last-child),
.asnwer ul:not(:last-child),
.asnwer ol:not(:last-child) {
    margin-bottom: 1rem;
}

.form {
    display: flex;
    align-items: stretch;
    gap: 1rem;
    margin-bottom: 2rem;
}

.form_login {
    flex-direction: column;
    margin-bottom: 0;
}

.form__control_inline {
    margin-bottom: 0;
}

.form__control_inline label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.pagination-form {
    justify-content: flex-end;
    margin-bottom: 0;
    align-items: center;
}

tfoot td {
    border: none;
}

.integrations-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.integrations-list__item {
    flex: 0 0 calc((100% / 3) - 4rem / 3);
    background-color: hsl(233 20% 23% / 1);
    padding: 2rem;
    border-radius: 15px;
    display: flex;
    gap: 2rem;
    align-items: center;
}

.integrations-list__item-header {
    margin-bottom: 1rem;
}

.integrations-list__item-description {
    margin-bottom: 1rem;
}

.breadcrumbs {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    align-items: center;
}

.breadcrumbs__link {
    text-decoration: underline;
    display: flex;
    align-items: center;
    gap: 0.35rem;
}

.logo-link {
    text-decoration: none;
}

.service-chooser {
    padding: 1.5rem 1.5rem 0 1.5rem;
}

.service-chooser .control {
    width: 100%;
}

.pagination__changer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
}

.confirm__description {
    margin: 1rem 0;
}

.confirm__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
}

@media (max-width: 1024px) {
    #root {
        grid-template-columns: 1fr;
        grid-template-areas: 'main';
        gap: 0;
    }

    main {
        margin-right: 1rem;
        margin-left: 1rem;
        grid-column-start: 1;
    }

    .main-nav {
        display: none;
    }

    .modal {
        min-width: 90dvw;
    }

    .top-nav {
        margin: 1rem 0;
    }
}

input[disabled],
textarea[disabled] {
    background-color: #c1c1c1;
    color: #5a5a5a;
}

@media (min-width: 1024px) {
    .hide-desktop {
        display: none;
    }
}

@media (max-width: 768px) {
    .hide-mobile {
        display: none;
    }
}

@media (max-width: 576px) {
    .account__name {
        display: none;
    }

    [type='search'] {
        display: none;
    }

    input,
    textarea,
    .control {
        padding: 0.5rem;
    }

    .section__header {
        flex-wrap: wrap;
    }

    .pagination-form {
        flex-wrap: wrap;
    }

    .pagination__changer {
        flex: 1 0 100%;
        justify-content: flex-end;
    }
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-color: #fff #0000;
    animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {
    content: '';
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
}
.loader::before {
    border-color: var(--primary-color) #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
}
.loader::after {
    margin: 8px;
}
@keyframes l16 {
    100% {
        transform: rotate(1turn);
    }
}

.modal h2 {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.error {
    color: #e17575;
}

.control-error {
    box-shadow: inset 0 0 1px 1px #e17575;
}

.global-loader {
    position: fixed;
    z-index: 2;
    left: 320px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.question-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.empty-list {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    background-color: #2f3246;
    padding: 2rem;
    border-radius: 15px;
}

.empty-list h3 {
    margin-bottom: 1rem;
}

.main-nav.show {
    display: block !important;
    z-index: 2;
    right: 0;
}

.question-box {
    position: fixed;
    bottom: -1rem;
    left: 320px;
    right: 0;
    padding: 1rem;
    background-color: var(--section-bg-color);
    box-shadow: 25px 2px 16px 6px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1024px) {
    .question-box {
        left: 0;
    }
}

.login__error {
    background-color: #fff7d6;
    color: #222433;
    padding: 1rem;
    line-height: 1;
    border-radius: 10px;
}

.token {
    background-color: rgb(47, 50, 70);
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
}

.token-remind {
    color: #b78fef;
    background-color: hsl(265deg 28.6% 25.83%);
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
}

.token-enabled {
    color: #74cd74;
}

.token-disabled {
    color: #cb5252;
}

.bar {
    width: 200px;
    height: 15px;
    background-color: #222433;
    border-radius: 10px;
}

.bar__progress {
    height: 100%;
    background-color: #621cc3;
    border-radius: 10px;
    max-width: 200px;
}

.usage {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.top-nav aside {
    display: flex;
    gap: 2rem;
}

@media (max-width: 768px) {
    .usage {
        flex-direction: column;
        gap: 0.5rem;
    }
}

.pricing {
    width: 100%;
    display: grid;
    margin-bottom: 3rem;
    margin-top: 2rem;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 1600px) {
    .pricing {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .pricing {
        grid-template-columns: 1fr;
    }
}

.pricing__card {
    border: 1px solid #2f3246;
    padding: 2rem;
    border-radius: 10px;
    transition: border-color 0.2s ease-in;
}

.pricing__card h3 {
    margin-bottom: 1rem;
}

.pricing__card li {
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.pricing-card__button {
    width: 100%;
    margin: 1rem 0 2rem;
}

.price {
    font-size: 1.2rem;
    margin-top: 1rem;
}

.price__value {
    font-size: 2.5rem;
    font-weight: bold;
}

.pricing__card_featured {
    border-color: var(--primary-color);
}

.usage__period {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: #b78fef;
}
