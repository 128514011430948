.account {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.account__name {
    font-size: 1.2rem;
    line-height: 1;
}

.account__welcome-text {
    color: #b78fef;
}

.account__avatar {
    border-radius: 50px;
    background-color: var(--primary-color);
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
}
